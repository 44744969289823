import ApiCommunicator from '../common/api_communicator';

class SmartSignUpClient {
    constructor(shopPublicToken, initCallback, configuration) {
        console.log('SmartSignUpClient::new');

        this._token = this.setToken(shopPublicToken);
        this._systemUrl = this.getUrl(configuration);
        this._itemCount = this.getItemCount(configuration);
        this._suggestionConfiguration = this.getSuggestionConfiguration(configuration);

        this._apiCommunicator = new ApiCommunicator({
            'MAC_HEADER': 'X-UPG-HMAC',
            'TOKEN_HEADER': 'X-UPG-TOKEN',
            'HMAC_ALGORITHM': 'SHA-512',
            'INIT_AUTOCOMPLETE': {url: this._systemUrl + 'init'},
            'GET_SUGGESTION': {url: this._systemUrl + 'suggestCompany'}
        });

        this._apiCommunicator.sendRequest('INIT_AUTOCOMPLETE', {token: this._token}, this._token).then((data) => {
            if (data.resultCode === 0) {
                this.initAutoComplete();
            }
            try {
                initCallback(data);
            } catch (err) {
                console.log(err);
            }
        });
    }

    initAutoComplete() {
        console.log('SmartSignUpClient::initAutoComplete');

        let dropdownStyle = document.getElementById("ssu-style");
        if (!dropdownStyle) {
            dropdownStyle = document.createElement("style");
            dropdownStyle.id = "ssu-style";
            dropdownStyle.textContent = `
            .ssu-control { position: absolute; min-width: 300px; }
            .ssu-box {
                background-color: #fff;
                border-radius: 0.25rem;
                padding: 0.25rem;
                box-shadow: 5px 11px 15px 5px rgb(0 0 0 / 50%);
                margin-top: 0px!important;
                list-style: none; 
            }
            .ssu-box li.highlight { background-color: #ddd; }
            .ssu-box li:hover { background-color: #ddd; }
            .ssu-box li { border-bottom: solid 1px #eee; padding: 2px }
            .ssu-highlight { color: #0080ff; }
            `;

            document.head.appendChild(dropdownStyle);
        }

        let companyField = document.querySelector("[data-crefopay-placeholder='address.name']");
        if (companyField) {
            // add anchor element for dropdown list
            if (!companyField.nextElementSibling) {
                let dropdownAnchor = document.createElement("div");
                dropdownAnchor.id = "company-name-dropdown-menu";
                dropdownAnchor.classList.add("ssu-control");
                companyField.parentNode.appendChild(dropdownAnchor);
            }

            let dropdownAnchor = document.getElementById("company-name-dropdown-menu");

            companyField.addEventListener("keyup", (event) => {
                if (!event.target.value || event.target.value.length < 3) {
                    dropdownAnchor.replaceChildren();
                } else if (event.key == "ArrowDown") {
                    let options = dropdownAnchor.querySelector("ul");
                    if (options) {
                        let selected = options.dataset.selected ? parseInt(options.dataset.selected) + 1 : 0;
                        if (selected >= options.children.length) {
                            selected = 0;
                        }
                        options.dataset.selected = selected;
                        this.highlightItem(options, selected);
                    }
                } else if (event.key == "ArrowUp") {
                    let options = dropdownAnchor.querySelector("ul");
                    if (options) {
                        let selected = options.dataset.selected ? parseInt(options.dataset.selected) - 1 : options.children.length;
                        if (selected < 0) {
                            selected = options.children.length - 1;
                        }
                        options.dataset.selected = selected;
                        this.highlightItem(options, selected);
                    }
                } else if (event.key == "Escape") {
                    dropdownAnchor.replaceChildren();
                } else if (event.key == "Enter") {
                    let options = dropdownAnchor.querySelector("ul");
                    if (options && options.dataset.selected) {
                        let selected = parseInt(options.dataset.selected);
                        let item = JSON.parse(options.children[selected].dataset.item);
                        this.itemSelected(item);
                    }
                    dropdownAnchor.replaceChildren();
                } else if (!event.ctrlKey && !event.altKey && event.key.length === 1) {
                    let searchTerm = event.target.value;
                    if (this._selectInputTimer) {
                        clearTimeout(this._selectInputTimer);
                    }
                    this._selectInputTimer = setTimeout(() => {
                        this._apiCommunicator.sendRequest('GET_SUGGESTION', {token: this._token, searchTerm: searchTerm}, this._token)
                            .then((response) => {
                                if (response.resultCode === 0) {
                                    let data = response.result.companyNameSuggestions.slice(0, this._itemCount);
                                    dropdownAnchor.replaceChildren(this.searchResult(dropdownAnchor, data, searchTerm));
                                }
                            });
                    }, 600);
                }
            });
        }
    }

    highlightItem(options, selected) {
        Array.from(options.children).forEach((item, id) => {
            if (id === selected) {
                item.classList.add("highlight");
            } else {
                item.classList.remove("highlight");
            }
        });
    }

    searchResult(dropdownAnchor, data, searchTerm) {
        const ul = document.createElement('ul')
        ul.classList.add('ssu-box');

        data.forEach((item) => {
            ul.appendChild(this.createListItem(dropdownAnchor, item, searchTerm))
        })

        return ul;
    }

    createListItem(dropdownAnchor, item, searchTerm) {

        const li = document.createElement('li')
        li.classList.add('ssu-li');
        li.dataset.item = JSON.stringify(item);

        var suggestionText = String(item.name).replace(new RegExp(searchTerm, "gi"), "<span class='ssu-highlight'>$&</span>");
        if (this._suggestionConfiguration["zip"]) {
            suggestionText += ", <span class='plz'>" + item.plz + "</span>";
        }
        if (this._suggestionConfiguration["city"]) {
            suggestionText += ", <span class='ort'>" + item.ort + "</span>";
        }
        if (this._suggestionConfiguration["county"]) {
            suggestionText += ", <span class='county'>" + item.bundesland + "</span>";
        }
        if (this._suggestionConfiguration["country"]) {
            suggestionText += ", <span class='country'>" + item.land + "</span>";
        }

        li.innerHTML = suggestionText;

        const selectEvent = (event) => {
            event.stopPropagation(); //parent elements will not have the same event 
            this.itemSelected(item);
            dropdownAnchor.replaceChildren();
        };

        li.addEventListener('click', selectEvent);
        li.addEventListener('touchstart', selectEvent); //, selectEvent, {"passive":true}

        return li
    }

    itemSelected(item) {
        this.setFields("[data-crefopay-placeholder='address.name']", item.name);
        this.setFields("[data-crefopay-placeholder='address.zip']", item.plz);
        this.setFields("[data-crefopay-placeholder='address.city']", item.ort);
        this.setFields("[data-crefopay-placeholder='address.state']", item.bundesland);
        this.setFields("[data-crefopay-placeholder='address.country']", item.land);

        var strasseHausnummer = item.strasseHausnummer;
        var streetNo = strasseHausnummer.match(/\d+\D*$/g);
        var street = strasseHausnummer;
        if (streetNo) {
            street = strasseHausnummer.substr(0, strasseHausnummer.length - streetNo[0].length);
            this.setFields("[data-crefopay-placeholder='address.streetNo']", streetNo[0]);
        }
        this.setFields("[data-crefopay-placeholder='address.streetWithNumber']", strasseHausnummer);
        this.setFields("[data-crefopay-placeholder='address.street']", street.trim());
        this.setFields("[data-crefopay-placeholder='crefoNo']", item.crefonummer);
    }

    setFields(selector, value) {
        [...document.querySelectorAll(selector)].forEach(e => e.value = value);
    }

    checkIfArgumentIsValid(argument, errorMessage) {
        if (typeof (argument) == 'undefined' || argument === null || (argument instanceof String && argument.trim() === '')) {
            throw errorMessage === null ? "invalidArgument" : errorMessage;
        }
    }

    setToken(shopPublicToken) {
        this.checkIfArgumentIsValid(shopPublicToken, "invalidToken");
        return shopPublicToken;
    }

    getUrl(configuration) {
        var configUrl = configuration && typeof configuration === 'object' ? configuration['url'] : configuration;
        return configUrl ? configUrl : 'https://api.crefopay.de/autocomplete/';
    }

    getSuggestionConfiguration(configuration) {
        var config = {"city": false, "county": false, "country": false, "zip": false};
        if (configuration && typeof configuration === 'object' && configuration['suggestion'] && typeof configuration['suggestion'] === 'object') {
            return configuration['suggestion'];
        }
        return config;
    }

    getItemCount(configuration) {
        var itemCount = configuration && typeof configuration === 'object' ? configuration['itemCount'] : 15;
        return itemCount ? itemCount : 15;
    }
}

export default SmartSignUpClient;